const policyList = [
    {
        value: 1,
        text: 'COMPLETE SPIN, SCRATCH, MATH QUIZ TO UNLOCK'
    },
    {
        value: 2,
        text: 'COMPLETE SPIN, SCRATCH, READ ARTICLE TO UNLOCK'
    },
    {
        value: 3,
        text: 'COMPLETE SPIN, SCRATCH, READ ARTICLE, VIDEOZONE TO UNLOCK'
    },
    {
        value: 4,
        text: 'COMPLETE SPIN, SCRATCH TO UNLOCK'
    },
    {
        value: 5,
        text: 'NO TASK REQUIRED'
    },
]
const playInList = [
    {
        value: 1,
        text: 'Browser'
    },
    {
        value: 2,
        text: 'Google Chrome'
    },
]
const optionList = [
    {
        value: 1,
        text: 'Option (1)'
    },
    {
        value: 2,
        text: 'Option (2)'
    },
    {
        value: 3,
        text: 'Option (3)'
    },
    {
        value: 4,
        text: 'Option (4)'
    },
]

export {policyList, playInList, optionList}